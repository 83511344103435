import React, { useEffect } from 'react';
import * as Yup from 'yup';
import {
  Card,
  Button,
  FormControl,
  ListItem,
  createClasses,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { DropdownField } from '../../../components/fields';
import { languages } from '../common/constants';
import { Language } from '../../../api/user';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  dropdown: {
    width: '296px',
  },
});

export interface SettingsData {
  language: Language;
}

interface PersonalInfoSettingsProps extends SettingsData {
  loading?: boolean;
  onSubmit: (data: SettingsData) => void;
  onDiscard: () => void;
}

export const PersonalInfoSettings: React.FC<PersonalInfoSettingsProps> = ({
  language,
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    language: Yup.string().required(
      t(
        'personalInfo.userSettings.edit.validation.language.required',
      ) as string,
    ),
  });

  const defaultValues: SettingsData = {
    language,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    reset({
      language,
    });
  }, [reset, language]);

  return (
    <FormProvider {...methods}>
      <Card
        title={t('personalInfo.userSettings.title') as string}
        actions={
          <>
            <Button
              onClick={onDiscard}
              variant="tertiary"
              className={classes.actionButton}
              aria-label="discard-button"
            >
              {t('general.buttons.discard')}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              className={classes.actionButton}
              variant="primary"
              aria-label="save-button"
              disabled={loading}
            >
              {t('general.buttons.save')}
            </Button>
          </>
        }
      >
        <FormControl className={classes.dropdown}>
          <DropdownField
            id="language"
            name="language"
            label={t('personalInfo.userSettings.language.label') || ''}
            data-testid="language-select"
            popupOverflow={false}
            className={classes.dropdown}
            disabled={loading}
          >
            <ListItem value={Language.en} title={languages(t)[Language.en]} />
            <ListItem value={Language.de} title={languages(t)[Language.de]} />
            <ListItem value={Language.nl} title={languages(t)[Language.nl]} />
          </DropdownField>
        </FormControl>
      </Card>
    </FormProvider>
  );
};
