import {
  UserSvcUserSetting,
  UserSvcUsersService as UserService,
} from '@kp/rest-api-javascript-sdk';

/**
 * Available languages
 */
export enum Language {
  en = 'en',
  de = 'de',
  nl = 'nl',
}

export type UserSettings = UserSvcUserSetting;

/**
 * Get user settings
 */
export const getUserSettings = UserService.getUserApiUsersMeSettings;

/**
 * Update user settings
 *
 * @param updates        The updates of the user setting
 */
export const updateUserSettings = UserService.patchUserApiUsersMeSettings;
