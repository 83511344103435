declare global {
  interface Window {
    ENV_ADMIN_APP_URI: string;
    ENV_AUTH_URI: string;
    ENV_REST_URI: string;
    ENV_BUILDING_MASTER_APP_URI: string;
    ENV_APPLICATIONINSIGHTS_CONNECTION_STRING: string;
  }
}

export const ADMIN_APP_URI = window.ENV_ADMIN_APP_URI;
export const AUTH_URI = window.ENV_AUTH_URI;
export const REST_URI = window.ENV_REST_URI;
export const BUILDING_MASTER_APP_URI = window.ENV_BUILDING_MASTER_APP_URI;
export const APPLICATIONINSIGHTS_CONNECTION_STRING =
  window.ENV_APPLICATIONINSIGHTS_CONNECTION_STRING;

// same for all environments
export const CLIENT_ID = 'kxp-account-app';
export const B2B_SUPPORT_EMAIL = 'hallo@brix2bytes.io';
